import React, { Component } from 'react';
import GetImageURL from '../../../Utils/GetImageURL';

class HomePageComponent extends Component {
    render() {
        return (
            <div>
                <br />
                <div className="card text-center">
                    <div className="card-body xc-card-body">
                        <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Head")} alt="Categories" />
                        <br/>
                        <h1 className="card-text">𝗜𝗻𝘃𝗲𝘀𝘁𝗺𝗲𝗻𝘁 𝗦𝗲𝗿𝘃𝗶𝗰𝗲𝘀 𝗔𝗻𝗱 𝗙𝗶𝗻𝗮𝗻𝗰𝗶𝗮𝗹 𝗠𝗮𝗻𝗮𝗴𝗲𝗺𝗲𝗻𝘁</h1>
                        <br />
                        <h4>𝗜𝗻𝘃𝗲𝘀𝘁 𝗦𝗺𝗮𝗿𝘁, 𝗟𝗶𝘃𝗲 𝗕𝗲𝘁𝘁𝗲𝗿</h4>
                        <h4>𝗠𝗮𝘅𝗶𝗺𝗶𝘇𝗲 𝗥𝗲𝘁𝘂𝗿𝗻𝘀, 𝗠𝗶𝗻𝗶𝗺𝗶𝘇𝗲 𝗥𝗶𝘀𝗸𝘀</h4>
                        <br/>
                    </div>
                </div>
                <br/><br/>
                <div className="card text-center mx-auto p-2 border border-success xc-home-tech-section">
                    <div className="card-header"><h4><strong>Investment Programme 2024 - 2025</strong></h4></div>
                    <div className="card-body">
                        <div className="container text-center">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Works")} alt="Categories" />
                                            <h4><strong>How Our Programme Works</strong></h4>
                                            <br/>
                                            <h5 className="card-title"><strong>Investment Slots</strong></h5>
                                            <h5 className="card-title">Slot 1 : ₹ 50,000</h5>
                                            <h5 className="card-title">Slot 2 : ₹ 1,00,000</h5>
                                            <br/>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Returns")} alt="Categories" />
                                            <h5 className="card-title"><strong>Monthly Returns/Profit</strong></h5>
                                            <h5 className="card-title">For Slot 1 : 5,000/-</h5>
                                            <h5 className="card-title">For Slot 2 : 10,000/-</h5>
                                            <p className="card-text">Monthly Returns Will Get Till Maturity Date</p>
                                            <br/>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Maturity")} alt="Categories" />
                                            <h5 className="card-title"><strong>Execution Duration</strong></h5>
                                            <h5 className="card-title">Starting Date : 01 October 2024</h5>
                                            <h5 className="card-title">Maturity Date : 01 October 2025</h5>
                                            <p className="card-text">Total Maturity Duration : 1 Year (12 Months)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>Choose & Deal With Us</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Deal")} alt="Categories" />
                                            <p className="card-text">Choose Investment Slots</p>
                                            <h5 className="card-title">Slot 1 : ₹ 50,000</h5>
                                            <h5 className="card-title">Slot 2 : ₹ 1,00,000</h5>
                                            <p className="card-text">Choose Investment Slot & Deal With Us</p>
                                            <p className="card-text"><strong>info.xcofin@gmail.com</strong></p>
                                            <a href="mailto:info.xcofin@gmail.com" className="btn btn-primary">Send Mail</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>Sign Investment Contracts</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Agreement")} alt="Categories" />
                                            <p className="card-text">Finalize Slots & Sign Investment Contracts</p>
                                            <h5 className="card-title">Investor Agreement</h5>
                                            <h5 className="card-title">Investment Bond</h5>
                                            <p className="card-text">Read Agreement & Bond Documents. Sign The Documents.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>Investment</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Investment")} alt="Categories" />
                                            <p className="card-text">Investment Distribution As Per Market Analysis</p>
                                            <h5 className="card-title">Equity Investments</h5>
                                            <h5 className="card-title">Dividend Income</h5>
                                            <h5 className="card-title">Derivatives - Options & Futures</h5>
                                            <h5 className="card-title">Alternative Investments</h5>
                                            <p className="card-text">Investing Money Based On Analysis & Conclusions</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>Investment Returns & Profit</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Profit")} alt="Categories" />
                                            <p className="card-text">Monthly Returns & Profit Are Fixed, Calculated Based On Slot Selected</p>
                                            <h5 className="card-title">Monthly Returns For Slot 1 : ₹ 5,000</h5>
                                            <h5 className="card-title">Monthly Returns For Slot 2 : ₹ 10,000</h5>
                                            <p className="card-text">Investor Will Get Monthly Returns Till Maturity Date</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>Programme Documentation & Reports</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Report")} alt="Categories" />
                                            <p className="card-text">After Maturity Date, We Will Publish : </p>
                                            <h5 className="card-title">Programme Summary Report</h5>
                                            <h5 className="card-title">Programme Closing Documents</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>Financial Freedom For Eveyone</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Satisfied")} alt="Categories" />
                                            <h4><strong>Customer Satisfaction</strong></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="card text-center">
                    <div className="card-body xc-card-body">
                        <h4>𝗜𝗻𝘃𝗲𝘀𝘁𝘀 𝘄𝗶𝘁𝗵 𝗖𝗼𝗻𝗳𝗶𝗱𝗲𝗻𝗰𝗲, 𝗠𝗮𝗻𝗮𝗴𝗶𝗻𝗴 𝘄𝗶𝘁𝗵 𝗣𝗿𝗲𝗰𝗶𝘀𝗶𝗼𝗻</h4>
                        <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Tagline")} alt="Categories" />
                        <p className="card-text">Providing comprehensive financial management solutions, including investment, asset management, deposits, payments, and credit systems. Our innovative services streamline financial operations, enhance asset growth, and ensure secure transactions, empowering clients to achieve their financial goals with confidence and efficiency.</p>
                        <div className="card text-center">
                            <div className="card-body">
                                <br/>
                                <h5 className="card-title">For Know More About Us, Visit Our Company Website</h5>
                                <p className="card-text">XCOLON (OPC) PRIVATE LIMITED</p>
                                <a href="https://www.xcolon.in" className="btn btn-primary" target="_blank" rel="noopener noreferrer">Visit Website</a>
                            </div>
                        </div>
                        <br/><br/>
                        <p className="card-text">Copyright © XCOLON (OPC) PRIVATE LIMITED. All rights reserved.</p>
                        <br/>
                    </div>
                </div>
            </div>
        )
    }
}
export default HomePageComponent